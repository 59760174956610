<template>
  <div>
    <draggable
      v-model="testDescriptors"
      :disabled="!allows_crud"
      v-bind="dragOptions"
      class="list-group"
      group="test_descriptor"
      handle=".handle"
    >
      <transition-group type="transition" name="flip-list">
        <template>
          <div
            v-for="test_descriptor in testDescriptors"
            :key="`test_descriptor-dragable-${test_descriptor.id}-${test_descriptor.test}-${test_descriptor.position}`"
          >
            <div
              v-if="
                user &&
                ((test_descriptor.visible_by_evaluatees == true &&
                  user.groups.includes(7)) ||
                  (!user.groups.includes(7) &&
                    ((preview_evaluation &&
                      test_descriptor.visible_by_evaluatees == true) ||
                      !preview_evaluation)))
              "
              class="descriptor-card mb-2"
              :class="{
                'print-hide':
                  test_descriptor.visible_by_evaluatees == false &&
                  preview_evaluation,
              }"
            >
              <div class="descriptor-header">
                <div
                  v-if="allows_crud"
                  class="descriptor-move handle"
                  :class="{ 'grabbable-cursor': allows_crud }"
                >
                  <b-icon
                    class="move-icon"
                    icon="arrows-expand"
                    scale="1.3"
                  ></b-icon>
                </div>
                <div
                  class="w-100 ml-1 mt-1 mb-1 rich-text-content"
                  v-html="test_descriptor.title"
                ></div>
                <div :class="{ 'container-buttons-allow-cruds': allows_crud }">
                  <b-button
                    v-if="
                      !test_descriptor.is_locked &&
                      allows_crud &&
                      user &&
                      !user.groups.includes(6)
                    "
                    variant="primary"
                    size="sm"
                    class="lock-btn p-0 mr-1"
                    v-b-tooltip.top.noninteractive.v-secondary="
                      'Bloquear Descriptor'
                    "
                    @click="changeStateDescriptor(test_descriptor)"
                  >
                    <b-icon-unlock-fill></b-icon-unlock-fill>
                  </b-button>
                  <b-button
                    v-if="
                      test_descriptor.is_locked &&
                      allows_crud &&
                      user &&
                      !user.groups.includes(6)
                    "
                    variant="primary"
                    size="sm"
                    class="lock-btn p-0 mr-1"
                    v-b-tooltip.top.noninteractive.v-secondary="
                      'Desbloquear Descriptor '
                    "
                    @click="changeStateDescriptor(test_descriptor)"
                  >
                    <b-icon-lock-fill></b-icon-lock-fill>
                  </b-button>
                  <b-button
                    variant="primary"
                    size="sm"
                    class="edit-btn mr-1 p-0"
                    v-if="allows_crud && !test_descriptor.is_locked"
                    v-b-tooltip.top.noninteractive.v-secondary="
                      `Editar ${$getVisibleNames(
                        'evaluations2.testdescriptor',
                        false,
                        'Descriptor'
                      )}`
                    "
                    @click="
                      $bvModal.show(
                        `modal-edit-descriptor-${test_descriptor.test}-${test_descriptor.position}-${test_descriptor.id}-${container_instrument_id}`
                      )
                    "
                  >
                    <b-icon-pencil-square></b-icon-pencil-square>
                  </b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    class="delete-btn p-0"
                    v-if="allows_crud && !test_descriptor.is_locked"
                    v-b-tooltip.top.noninteractive.v-secondary="
                      `Eliminar ${$getVisibleNames(
                        'evaluations2.testdescriptor',
                        false,
                        'Descriptor'
                      )}`
                    "
                    @click="askForDeleteDescriptor(test_descriptor.id)"
                  >
                    <!-- v-can="'evaluations2.delete_evaluation'" -->
                    <b-icon-trash></b-icon-trash>
                  </b-button>
                </div>
              </div>
              <div
                class="descriptor-body"
                v-if="test_descriptor.description.trim() != ''"
              >
                <div
                  class="w-100 rich-text-content"
                  v-html="test_descriptor.description"
                ></div>
              </div>
            </div>
            <!-- modals -->
            <b-modal
              :id="`modal-edit-descriptor-${test_descriptor.test}-${test_descriptor.position}-${test_descriptor.id}-${container_instrument_id}`"
              :title="`Editar ${$getVisibleNames(
                'evaluations2.testdescriptor',
                false,
                'Descriptor'
              )}`"
              hide-footer
              size="lg"
              no-enforce-focus
            >
              <TestDescriptorForm
                :test_id="test_id"
                :position_number="position_number"
                :testDescriptor="test_descriptor"
                @closeModal="
                  $bvModal.hide(
                    `modal-edit-descriptor-${test_descriptor.test}-${test_descriptor.position}-${test_descriptor.id}-${container_instrument_id}`
                  )
                "
              ></TestDescriptorForm>
            </b-modal>
          </div>
        </template>
      </transition-group>
    </draggable>
    <div class="container-buttons mb-2">
      <b-button
        v-if="allows_crud && test && !test.descriptor_creation_is_locked"
        variant="primary"
        size="sm"
        @click="
          $bvModal.show(
            `modal-created-descriptor-${test_id}-${position_number}-${container_instrument_id}`
          )
        "
      >
        + Agregar
        {{
          $getVisibleNames("evaluations2.testdescriptor", false, "Descriptor")
        }}
      </b-button>
    </div>
    <b-modal
      :id="`modal-created-descriptor-${test_id}-${position_number}-${container_instrument_id}`"
      :title="`Crear ${$getVisibleNames(
        'evaluations2.testdescriptor',
        false,
        'Descriptor'
      )}`"
      hide-footer
      size="lg"
      no-enforce-focus
    >
      <TestDescriptorForm
        :test_id="test_id"
        :position_number="position_number"
        :order="
          testDescriptors.length > 0
            ? testDescriptors[testDescriptors.length - 1].order + 1
            : 1
        "
        @closeModal="
          $bvModal.hide(
            `modal-created-descriptor-${test_id}-${position_number}-${container_instrument_id}`
          )
        "
      ></TestDescriptorForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import { toast } from "@/utils/utils";

export default {
  name: "TestDescriptorsDraggable",
  components: {
    draggable,
    TestDescriptorForm: () =>
      import("@/components/new-evaluations/Evaluation/TestDescriptorForm"),
  },
  props: {
    test_id: {
      type: Number,
      required: true,
    },
    preview_evaluation: {
      type: Boolean,
    },
    container_instrument_id: {
      typle: String,
    },
    position_number: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      test_descriptors: names.NEW_TEST_DESCRIPTORS,
      tests: names.NEW_TESTS,
    }),
    test() {
      return this.tests.find((x) => x.id == this.test_id);
    },
    testDescriptors: {
      get() {
        return this.test_descriptors
          .filter(
            (x) => x.position == this.position_number && x.test == this.test_id
          )
          .sort((a, b) => a.order - b.order);
      },
      set(list) {
        list.forEach((element, index) => {
          if (!isNaN(element.id)) {
            let payload = {
              new_test_descriptor_id: element.id,
              item: {
                order: index + 1,
                position: this.position_number,
              },
            };
            this.$store.dispatch(names.PATCH_NEW_TEST_DESCRIPTOR, payload);
          }
        });
      },
    },
    dragOptions() {
      return {
        animation: 0,
        group: "test_descriptor",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    changeStateDescriptor(test_descriptor) {
      const payload = {
        new_test_descriptor_id: test_descriptor.id,
        item: {
          is_locked: !test_descriptor.is_locked,
        },
      };
      this.$store.dispatch(names.PATCH_NEW_TEST_DESCRIPTOR, payload);
    },
    askForDeleteDescriptor(descriptor_id) {
      this.$swal({
        title: `<p>¿Está seguro de que desea eliminar el ${this.$getVisibleNames(
          "evaluations2.testdescriptor",
          false,
          "Descriptor"
        )}?</p>`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_NEW_TEST_DESCRIPTOR, descriptor_id);
          toast(
            "Se eliminó el" +
              this.$getVisibleNames(
                "evaluations2.testdescriptor",
                false,
                "Descriptor"
              )
          );
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.lock-btn {
  border: 1px;
  max-height: 27px;
  width: 27px;
}
.container-buttons-allow-cruds {
  display: flex;
  padding: 5px;
}
.descriptor-move {
  background: var(--primary-color);
  width: 35px;
  display: flex;
}
.descriptor-move:hover {
  box-shadow: 0px 4px 12px -2px var(--secondary-color);
  background-color: var(--kl-menu-color);
  color: white;
}
.grabbable-cursor {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable-cursor:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.move-icon {
  margin: auto;
  cursor: grab;
}
.descriptor-card {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
}
.descriptor-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.descriptor-body {
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 5px;
}
.container-buttons {
  display: flex;
  justify-content: center;
}
.edit-btn {
  border: 1px;
  max-height: 27px;
  width: 27px;
}
.edit-btn:hover {
  background-color: var(--kl-primary-button-hover-color);
}
.delete-btn {
  border: 1px;
  max-height: 27px;
  width: 27px;
}
.delete-btn:hover {
  background-color: #e45d6b;
}
.rich-text-content >>> p {
  margin-bottom: 0px !important;
}
@media print {
  .print-hide {
    display: none !important;
  }
  .print-show {
    display: block !important;
  }
}
</style>

