var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('draggable',_vm._b({staticClass:"list-group",attrs:{"disabled":!_vm.allows_crud,"group":"test_descriptor","handle":".handle"},model:{value:(_vm.testDescriptors),callback:function ($$v) {_vm.testDescriptors=$$v},expression:"testDescriptors"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},[_vm._l((_vm.testDescriptors),function(test_descriptor){return _c('div',{key:`test_descriptor-dragable-${test_descriptor.id}-${test_descriptor.test}-${test_descriptor.position}`},[(
              _vm.user &&
              ((test_descriptor.visible_by_evaluatees == true &&
                _vm.user.groups.includes(7)) ||
                (!_vm.user.groups.includes(7) &&
                  ((_vm.preview_evaluation &&
                    test_descriptor.visible_by_evaluatees == true) ||
                    !_vm.preview_evaluation)))
            )?_c('div',{staticClass:"descriptor-card mb-2",class:{
              'print-hide':
                test_descriptor.visible_by_evaluatees == false &&
                _vm.preview_evaluation,
            }},[_c('div',{staticClass:"descriptor-header"},[(_vm.allows_crud)?_c('div',{staticClass:"descriptor-move handle",class:{ 'grabbable-cursor': _vm.allows_crud }},[_c('b-icon',{staticClass:"move-icon",attrs:{"icon":"arrows-expand","scale":"1.3"}})],1):_vm._e(),_c('div',{staticClass:"w-100 ml-1 mt-1 mb-1 rich-text-content",domProps:{"innerHTML":_vm._s(test_descriptor.title)}}),_c('div',{class:{ 'container-buttons-allow-cruds': _vm.allows_crud }},[(
                    !test_descriptor.is_locked &&
                    _vm.allows_crud &&
                    _vm.user &&
                    !_vm.user.groups.includes(6)
                  )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    'Bloquear Descriptor'
                  ),expression:"\n                    'Bloquear Descriptor'\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"lock-btn p-0 mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.changeStateDescriptor(test_descriptor)}}},[_c('b-icon-unlock-fill')],1):_vm._e(),(
                    test_descriptor.is_locked &&
                    _vm.allows_crud &&
                    _vm.user &&
                    !_vm.user.groups.includes(6)
                  )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    'Desbloquear Descriptor '
                  ),expression:"\n                    'Desbloquear Descriptor '\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"lock-btn p-0 mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.changeStateDescriptor(test_descriptor)}}},[_c('b-icon-lock-fill')],1):_vm._e(),(_vm.allows_crud && !test_descriptor.is_locked)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    `Editar ${_vm.$getVisibleNames(
                      'evaluations2.testdescriptor',
                      false,
                      'Descriptor'
                    )}`
                  ),expression:"\n                    `Editar ${$getVisibleNames(\n                      'evaluations2.testdescriptor',\n                      false,\n                      'Descriptor'\n                    )}`\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"edit-btn mr-1 p-0",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
                      `modal-edit-descriptor-${test_descriptor.test}-${test_descriptor.position}-${test_descriptor.id}-${_vm.container_instrument_id}`
                    )}}},[_c('b-icon-pencil-square')],1):_vm._e(),(_vm.allows_crud && !test_descriptor.is_locked)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    `Eliminar ${_vm.$getVisibleNames(
                      'evaluations2.testdescriptor',
                      false,
                      'Descriptor'
                    )}`
                  ),expression:"\n                    `Eliminar ${$getVisibleNames(\n                      'evaluations2.testdescriptor',\n                      false,\n                      'Descriptor'\n                    )}`\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"delete-btn p-0",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.askForDeleteDescriptor(test_descriptor.id)}}},[_c('b-icon-trash')],1):_vm._e()],1)]),(test_descriptor.description.trim() != '')?_c('div',{staticClass:"descriptor-body"},[_c('div',{staticClass:"w-100 rich-text-content",domProps:{"innerHTML":_vm._s(test_descriptor.description)}})]):_vm._e()]):_vm._e(),_c('b-modal',{attrs:{"id":`modal-edit-descriptor-${test_descriptor.test}-${test_descriptor.position}-${test_descriptor.id}-${_vm.container_instrument_id}`,"title":`Editar ${_vm.$getVisibleNames(
              'evaluations2.testdescriptor',
              false,
              'Descriptor'
            )}`,"hide-footer":"","size":"lg","no-enforce-focus":""}},[_c('TestDescriptorForm',{attrs:{"test_id":_vm.test_id,"position_number":_vm.position_number,"testDescriptor":test_descriptor},on:{"closeModal":function($event){return _vm.$bvModal.hide(
                  `modal-edit-descriptor-${test_descriptor.test}-${test_descriptor.position}-${test_descriptor.id}-${_vm.container_instrument_id}`
                )}}})],1)],1)})],2)],1),_c('div',{staticClass:"container-buttons mb-2"},[(_vm.allows_crud && _vm.test && !_vm.test.descriptor_creation_is_locked)?_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
          `modal-created-descriptor-${_vm.test_id}-${_vm.position_number}-${_vm.container_instrument_id}`
        )}}},[_vm._v(" + Agregar "+_vm._s(_vm.$getVisibleNames("evaluations2.testdescriptor", false, "Descriptor"))+" ")]):_vm._e()],1),_c('b-modal',{attrs:{"id":`modal-created-descriptor-${_vm.test_id}-${_vm.position_number}-${_vm.container_instrument_id}`,"title":`Crear ${_vm.$getVisibleNames(
      'evaluations2.testdescriptor',
      false,
      'Descriptor'
    )}`,"hide-footer":"","size":"lg","no-enforce-focus":""}},[_c('TestDescriptorForm',{attrs:{"test_id":_vm.test_id,"position_number":_vm.position_number,"order":_vm.testDescriptors.length > 0
          ? _vm.testDescriptors[_vm.testDescriptors.length - 1].order + 1
          : 1},on:{"closeModal":function($event){return _vm.$bvModal.hide(
          `modal-created-descriptor-${_vm.test_id}-${_vm.position_number}-${_vm.container_instrument_id}`
        )}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }